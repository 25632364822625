import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2, styleMixins } from '@grafana/ui';
import { BrandComponentProps, Branding } from 'app/core/components/Branding/Branding';

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/nijhuis/nijhuis-logo.png" alt="Nijhuis i-MONITORING" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();

  const background = css`
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url(public/img/nijhuis/nijhuis_login_dark.svg);
      background-position: top center;
      background-size: auto;
      background-repeat: no-repeat;

      opacity: 0;
      transition: opacity 3s ease-in-out;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.md)} {
        background-position: center;
        background-size: cover;
      }
    }
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/nijhuis/nijhuis-logo-brand.png" alt="Nijhuis i-MONITORING" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css`
    background: ${colorManipulator.alpha(theme.colors.background.primary, 0.7)};
    background-size: cover;
  `;
};

export class NijhuisBranding extends Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Nijhuis i-MONITORING';
  static LoginTitle = 'Welcome to Nijhuis i-MONITORING';
  static GetLoginSubTitle = () => {
    const slogans = [
      'We help industries, utilities and cities to reduce, reuse and recover to advance water and climate change adaption',
      'Solid Solutions In A Fluid World',
      'Sustainable Water Use, Energy and Resource Recovery',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
